import { FC } from 'react';

import { useTranslation } from 'react-i18next';

import ecLogo from '../../_assets/svg/eu-logo_grey.svg';
import { useToggle } from '../../_hooks';
import { Menu, TRoute } from '../menu/Menu';
import PageHeader from '../pageHeader/PageHeader';

import { LanguageList } from './components';

import './siteHeader.scss';

/**
 * EC Component: Siteheader
 * html: https://ec.europa.eu/component-library/playground/ec/?path=/story/components-site-headers-core--logged-in
 * js: https://github.com/ec-europa/europa-component-library/tree/v3-dev/src/implementations/vanilla/components/site-header-core
 */

const SiteHeader: FC = () => {
  const [isLanguageOpen, toggleLanguage] = useToggle(false);
  const [isMenuOpen, toggleMenu] = useToggle(false);
  const { t } = useTranslation();

  const routes: TRoute[] = [
    { label: t('SHARED.NAVIGATION.HOME'), link: '/home' },
    { label: t('SHARED.NAVIGATION.EVENTS'), link: '/events' },
    { label: t('SHARED.NAVIGATION.COMMUNITY'), link: '/community' },
    { label: t('SHARED.NAVIGATION.HOW_IT_WORKS'), link: '/how-it-works' },
  ];

  const handleLanguageMenu = (nextValue?: boolean) => {
    toggleMenu(false);
    toggleLanguage(nextValue);
  };

  const handleMenuToggle = (nextValue?: boolean) => {
    toggleLanguage(false);
    toggleMenu(nextValue);
  };

  return (
    <>
      <header
        className="ecl-site-header ecl-site-header-with-logo-l ecl-site-header--has-menu"
        data-ecl-auto-init="SiteHeader"
        id="site-header"
      >
        <div className="ecl-site-header__background">
          <div className="ecl-site-header__header">
            <div className="ecl-site-header__container ecl-container">
              <div className="ecl-site-header__top" data-ecl-site-header-top>
                <a
                  aria-label={t('SITE_HEADER.EC_HOME')}
                  className="ecl-link ecl-link--standalone ecl-site-header__logo-link"
                  href="https://commission.europa.eu/index_en"
                >
                  <picture className="ecl-picture ecl-site-header__picture" title={t('SITE_HEADER.EC_HOME')}>
                    <source media="(min-width: 996px)" srcSet={ecLogo} />
                    <img alt={t('SITE_HEADER.EC_LOGO_ALT')} className="ecl-site-header__logo-image" src={ecLogo} />
                  </picture>
                </a>
              </div>
            </div>
          </div>
        </div>
      </header>
      <PageHeader />
      <Menu isMenuOpen={isMenuOpen} routes={routes} toggleMenu={handleMenuToggle} />
      <LanguageList isLanguageMenuOpen={isLanguageOpen} toggleLanguageMenu={handleLanguageMenu} />
    </>
  );
};

export default SiteHeader;
