import { FC, useLayoutEffect } from 'react';

import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';

import Toastify from './Toastify';
import { useTrackAnalytics } from './_hooks';
import { BackToTop } from './_shared';
import { ModalOpener } from './_shared/modal/ModalOpener';
import ModalWrapper from './_shared/modal/ModalWrapper';
import { Callback } from './auth/_components';
import { AuthContextProvider } from './auth/_context';
import About from './community/about/About';
import { ChatSessionContextProvider } from './conversations/_context';
import Footer from './ec/footer/Footer';
import SiteHeader from './ec/siteHeader/SiteHeader';
import EventsRoutes from './events/EventsRoutes';
import { EventsContextProvider } from './events/_context/EventsContext';
import Home from './home/Home';
import HowItWorks from './howItWorks/HowItWorks';
import Profile from './profile/Profile';

const App: FC = () => {
  const { pathname } = useLocation();
  const { t } = useTranslation();

  useLayoutEffect(() => ModalOpener.instance.closeAll(), [pathname]);
  useLayoutEffect(() => window.scrollTo({ behavior: 'smooth', top: 0 }), [pathname]);
  useTrackAnalytics();

  return (
    <AuthContextProvider>
      <Helmet titleTemplate={`%s | ${t('TITLE')}`} />
      <ChatSessionContextProvider>
        <SiteHeader />
        <Routes>
          <Route element={<Home />} path="home" />
          <Route element={<EventsRoutes />} path="events/*" />
          <Route element={<About />} path="community" />
          <Route element={<HowItWorks />} path="how-it-works" />
          <Route
            element={
              <EventsContextProvider>
                <Profile />
              </EventsContextProvider>
            }
            path="profile"
          />
          <Route element={<Callback />} path="login/callback" />
          <Route element={<Navigate to="home" />} path="*" />
        </Routes>
        <Footer />
        <ModalWrapper />
      </ChatSessionContextProvider>
      <BackToTop />
      <Toastify />
    </AuthContextProvider>
  );
};

export default App;
