import { FC } from 'react';

import { useTranslation } from 'react-i18next';

import { About, Documentation, ExternalResources, Icon, Modal, Speaker } from '../../../_shared';
import { formatISOString, DEFAULT_MONTH_STRING_FORMAT, BASE_TIME_STRING_FORMAT } from '../../../_utils/dateHelpers';
import { TSession } from '../../_models';

import './detailModal.scss';

type TProps = {
  session: TSession;
};

const DetailModal: FC<TProps> = ({ session }) => {
  const { t } = useTranslation();

  return (
    <Modal title={t('EVENT.PROGRAMME.SESSION_DETAILS')}>
      <div className="detail-modal__content">
        <h3>{session.title}</h3>

        <div>
          <div>
            <Icon className="icon" name="calendar" />
            <span>{formatISOString(session.startTime, DEFAULT_MONTH_STRING_FORMAT)}</span>
          </div>
          <div>
            <Icon className="icon" name="SvgClock" />
            <span>{formatISOString(session.startTime, BASE_TIME_STRING_FORMAT)}</span>
            <span className="session__time__divider">-</span>
            <span>{formatISOString(session.endTime, BASE_TIME_STRING_FORMAT)}</span>
          </div>
        </div>

        <hr />

        {session.speakers.length > 0 && (
          <section>
            <h3>{t('EVENT.NAVIGATION.SPEAKERS')}</h3>
            {session.speakers?.map(speaker => (
              <Speaker key={speaker.id} speaker={speaker} />
            ))}
          </section>
        )}

        <About summary={session.summary} title={t('EVENT.LIVE_SESSIONS.ABOUT')} topics={session.topics} />
        {session.type !== 'WORKSHOP' && <Documentation documents={session?.documentation} />}
        {session.type !== 'WORKSHOP' && <ExternalResources links={session?.links} />}
      </div>
    </Modal>
  );
};

export default DetailModal;
