import { FC } from 'react';

import { useTranslation } from 'react-i18next';

import { Circle, Title } from '../../../_shared';

import './steps.scss';

const Steps: FC = () => {
  const { t } = useTranslation();
  const LinkText = ({ text, url }: { text: string; url: string }) => (
    <a className="ecl-link" href={url} rel="noreferrer" target="_blank">
      {text}
    </a>
  );
  return (
    <section className="steps">
      <div className="ecl-container">
        <Title>{t('SHARED.NAVIGATION.MEET_THE_COMMUNITY')}</Title>
        <div className="steps__single">
          <Circle>1</Circle>
          <div className="steps__single__content">
            <h3>Are you interested in applying for funding through Horizon Europe?</h3>
            <p>
              The network of{' '}
              <LinkText
                text="National Contact Points"
                url="https://ec.europa.eu/info/funding-tenders/opportunities/portal/screen/support/ncp?order=ASC&pageNumber=0&pageSize=50&countries=20000832,20000839,20000841,20000911,20000871,20000872,20000875,20000880,20000885,20000890,20000873,20000902,20000913,20000915,20000922,20000946,20000944,20000945,20000960,20000973,20000986,20000990,20000994,20001005,20001004,20000883,20001001"
              />{' '}
              stands ready to answer any questions you might have on the application process in your own language.
            </p>
          </div>
        </div>
        <div className="steps__single">
          <Circle>2</Circle>
          <div className="steps__single__content">
            <h3>Are you an entrepreneur looking to innovate and grow internationally?</h3>
            <p>
              Get in touch with the closest <LinkText text="Enterprise Europe Network" url="https://een.ec.europa.eu/" /> Partner!
              Our local experts will help you with free, tailored services to bring your business to the next level.
            </p>
          </div>
        </div>
        <div className="steps__single">
          <Circle>3</Circle>
          <div className="steps__single__content">
            <h3>Are you looking for answers about European research and innovation?</h3>
            <p>
              The{' '}
              <LinkText
                text="Research Enquiry service"
                url="https://research-and-innovation.ec.europa.eu/contact-us/research-enquiry-service_en"
              />{' '}
              deals with questions regarding European Research, available funding instruments in the field of research and the
              validation process of participants.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};
export default Steps;
