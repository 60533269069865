import { FC } from 'react';

import * as reactHelmet from 'react-helmet';
import { useTranslation } from 'react-i18next';

import { IntroCommunity } from '../_components';
import Steps from '../_components/steps/Steps';
import './about.scss';

const About: FC = () => {
  const { t } = useTranslation();
  return (
    <main>
      <reactHelmet.Helmet>
        <title>{t('SHARED.NAVIGATION.COMMUNITY')}</title>
      </reactHelmet.Helmet>
      <IntroCommunity />
      <Steps />
    </main>
  );
};

export default About;
