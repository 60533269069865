import { FC, useState } from 'react';

import { eachDayOfInterval } from 'date-fns';
import { useTranslation } from 'react-i18next';

import { Sort, TUser } from '../_models';
import { Checkbox, FilterBar, Person, SearchField, Spinner, Title } from '../_shared';
import { DEFAULT_YEAR_STRING_FORMAT, formatDate } from '../_utils/dateHelpers';
import { useAuthContext } from '../auth/_context';
import { useEventContext } from '../events/_context/EventContext';

import { TSpeakersQuery } from './_models';
import { useGetSpeakers } from './_queries';

import './speakers.scss';

const initialQuery: TSpeakersQuery = {
  allFollowers: false,
  allIFollow: false,
  channelId: '',
  date: '',
  search: '',
  sort: Sort.Alphabetical,
  topics: [],
};

const Speakers: FC = () => {
  const { t } = useTranslation();
  const { event, topics } = useEventContext();
  const { isLoggedIn } = useAuthContext();
  const [query, setQuery] = useState<TSpeakersQuery>(initialQuery);
  const { data: speakers, isLoading } = useGetSpeakers(query);
  const [selectedPeople, setSelectedPeople] = useState<TUser[]>([]);

  function selectPerson(person: TUser): void {
    if (selectedPeople.includes(person)) setSelectedPeople(selectedPeople.filter(p => p !== person));
    else setSelectedPeople([...selectedPeople, person]);
  }

  const updateQuery = (overrides: Partial<TSpeakersQuery>) => {
    setQuery({ ...query, ...overrides });
  };

  const handleTopicsChange = (checked: boolean, name: string) => {
    updateQuery({ topics: checked ? [...query.topics, name] : query.topics.filter(topic => topic !== name) });
  };

  const handleDaysChange = (checked: boolean, date: string) => updateQuery({ date: checked ? date : ' ' });

  return (
    <main className="speakers ecl-container">
      <Title>{t('EVENT.NAVIGATION.SPEAKERS')}</Title>

      <section className="speakers__content">
        <FilterBar clearFilters={() => updateQuery(initialQuery)}>
          <SearchField label={t('SPEAKERS.SEARCH_SPEAKER')} onSearch={search => updateQuery({ search })} />

          <h4>{t('SPEAKERS.FILTERS.DAYS')}</h4>
          {eachDayOfInterval({ end: new Date(event.endDate), start: new Date(event.startDate) }).map((date, index) => (
            <Checkbox
              checked={formatDate(date, DEFAULT_YEAR_STRING_FORMAT) === query?.date}
              key={formatDate(date, DEFAULT_YEAR_STRING_FORMAT)}
              name={formatDate(date, DEFAULT_YEAR_STRING_FORMAT)}
              onChange={handleDaysChange}
            >
              {t('SHARED.DAY_COUNT', { count: index + 1 })}
            </Checkbox>
          ))}
          <h4>{t('SHARED.TOPICS')}</h4>
          {topics.map(topic => (
            <Checkbox
              checked={query.topics.includes(topic.title)}
              key={topic.title}
              name={topic.title}
              onChange={handleTopicsChange}
            >
              {topic.title}
            </Checkbox>
          ))}
          <h4>{t('SHARED.FAVOURITES')}</h4>
          <Checkbox
            checked={query.allFollowers}
            disabled={!isLoggedIn}
            name="allFollowers"
            onChange={allFollowers => updateQuery({ allFollowers, allIFollow: false })}
            type="toggle"
          >
            {t('SPEAKERS.ALL_FOLLOWERS')}
          </Checkbox>
          <Checkbox
            checked={query.allIFollow}
            disabled={!isLoggedIn}
            name="allIFollow"
            onChange={allIFollow => updateQuery({ allFollowers: false, allIFollow })}
            type="toggle"
          >
            {t('SPEAKERS.ALL_I_FOLLOW')}
          </Checkbox>
        </FilterBar>
        <div className="speakers__content__container">
          <div className="speakers__content__container__grid">
            {isLoading ? (
              <Spinner />
            ) : (
              <>
                {!speakers?.length && <p>{t('SPEAKERS.NO_SPEAKERS')}</p>}
                {(speakers || []).map(user => (
                  <Person
                    isSelected={selectedPeople.includes(user)}
                    key={user.id}
                    onSelectChange={() => selectPerson(user)}
                    user={user}
                  />
                ))}
              </>
            )}
          </div>
        </div>
      </section>
    </main>
  );
};
export default Speakers;
