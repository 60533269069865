import { FC, useCallback, useEffect } from 'react';

import { useTranslation } from 'react-i18next';

import { useToggle } from '../../_hooks';
import { TUser, getUserName } from '../../_models';
import { useGetUserEvents } from '../../_queries';
import { getLabelType } from '../../_utils/eventHelpers';
import { getSocials } from '../../_utils/userHelpers';
import Card from '../../ec/card/Card';
import CardTag from '../../ec/card/components/CardTag';
import Button from '../button/Button';
import Icon from '../icon/Icon';
import { ModalOpener } from '../modal/ModalOpener';
import ShowMore from '../showMore/ShowMore';
import Spinner from '../spinner/Spinner';

import './userModal.scss';

type TProps = {
  user: TUser;
};

const UserModal: FC<TProps> = ({ user }) => {
  const { t } = useTranslation();
  const socials = getSocials(user);
  const { data: events, isLoading: eventsLoading } = useGetUserEvents(user.id);
  const [showMoreEvents, toggleShowMoreEvents] = useToggle(false);
  const MAX_INITIAL = 2;

  const handleKeyDown = useCallback(event => {
    if (event.key === 'Escape') ModalOpener.instance.close();
  }, []);

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return () => document.removeEventListener('keydown', handleKeyDown);
  }, []);

  return (
    <div className="user-detail user-modal">
      <Button hideLabel icon="close" onClick={() => ModalOpener.instance.close()} theme="ghost">
        {t('SHARED.BUTTONS.CLOSE')}
      </Button>
      <section className="user-detail__information">
        <div className="user-detail__information__content">
          {user?.image ? <img alt="speaker-image" src={user.image} /> : <Icon name="SvgUser" size={8} />}

          <div className="user-detail__information__content__text">
            <h3>{getUserName(user)}</h3>
            <div className="user-info-line">
              <Icon name="SvgWork" size={1} />

              <div>
                <span>{`${user?.organisationType || ''}${user?.organisation ? ' - ' : user?.organisationType ? '' : '-'}${
                  user?.organisation || ''
                }`}</span>

                <span>{t('PROFILE.POSITION', { position: user?.position || '-' })}</span>
              </div>
            </div>
            <div className="user-info-line">
              <Icon name="SvgLocation" size={1} />

              <span>{t('PROFILE.LOCATION', { location: user?.nationality || '-' })}</span>
            </div>
          </div>
        </div>
      </section>

      {user?.biography && (
        <section className="user-detail__bio">
          <h2>{t('PROFILE.BIO')}</h2>
          <p>{user?.biography}</p>
        </section>
      )}

      {!!user?.interests?.length && (
        <section className="user-detail__topics">
          <h2>{t('PROFILE.TOPICS')}</h2>

          <div>
            {user?.interests.sort().map(interest => (
              <CardTag key={interest} tag={interest} />
            ))}
          </div>
        </section>
      )}

      {!!socials?.length && (
        <section className="user-detail__socials">
          <h2>{t('PROFILE.SOCIALS')}</h2>

          {socials.map(social => (
            <Button href={social.url} icon={social.icon} iconPosition="before" key={social.url} theme="ghost">
              {social.name}
            </Button>
          ))}
        </section>
      )}

      {((user?.email && user?.canContact) || user?.website) && (
        <section className="user-detail__contact">
          <h2>{t('PROFILE.CONTACT')}</h2>

          {user?.email && user?.canContact && (
            <Button href={`mailto:${user?.email}`} icon="SvgMailOutline" iconPosition="before" theme="plain-link">
              {user?.email}
            </Button>
          )}

          {user?.website && (
            <Button href={user?.website} icon="SvgLink" iconPosition="before" theme="plain-link">
              {user?.website}
            </Button>
          )}
        </section>
      )}

      {(eventsLoading || events?.length > 0) && (
        <section className="user-detail__events">
          <h2>{t('EVENTS.MEMBER_INTERESTED')}</h2>
          {eventsLoading ? (
            <Spinner />
          ) : (
            <div className="user-detail__events__grid">
              {events.slice(0, showMoreEvents ? events.length : MAX_INITIAL).map(event => (
                <Card
                  date={event?.startDate}
                  image={event?.image?.url}
                  imageAlt={event?.image?.name}
                  key={event?.id}
                  labelType={getLabelType(event?.startDate, event?.endDate)}
                  link={`${event?.id}/overview`}
                  tags={event?.topics}
                  title={event?.title}
                />
              ))}
              {events?.length > MAX_INITIAL && (
                <span className="show-more">
                  <span className="show-more__text">{`${showMoreEvents ? events?.length : MAX_INITIAL} results of ${
                    events?.length
                  }`}</span>
                  <ShowMore onClick={toggleShowMoreEvents} open={showMoreEvents}>
                    {t(showMoreEvents ? 'SHARED.BUTTONS.SHOW_LESS' : 'SHARED.BUTTONS.SHOW_MORE')}
                  </ShowMore>
                </span>
              )}
            </div>
          )}
        </section>
      )}
    </div>
  );
};

export default UserModal;
