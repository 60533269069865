import { normalizeText } from '../_utils/normalizeHelpers';

export type TUser = {
  achievements?: string[];
  biography?: string;
  bookAMeetingId: string;
  calendlyUrl?: string;
  canContact: boolean;
  chatId: string;
  email: string;
  facebook?: string;
  firstName: string;
  id: string;
  image: string;
  interests: string[];
  isConnection?: boolean;
  isSpeaker: boolean;
  isVisible: boolean;
  lastName: string;
  linkedin?: string;
  nationality: string;
  organisation: string;
  organisationType: string;
  position: string;
  residence: string;
  twitter?: string;
  website?: string;
};

export function getUserName(user: TUser): string {
  if (!user) return null;
  return normalizeText(user.lastName ? `${user.firstName} ${user.lastName}` : user.firstName);
}
