import { FC } from 'react';

import { getUserName, TUser } from '../../_models';
import Button from '../button/Button';
import Icon from '../icon/Icon';
import { ModalOpener } from '../modal/ModalOpener';
import UserModal from '../userModal/UserModal';

import './person.scss';

type TProps = {
  isSelected?: boolean;
  onSelectChange?: () => void;
  user: TUser;
};

const Person: FC<TProps> = ({ user }) => {
  const getUserOrganisation = () => {
    if (user?.organisation?.length > 40) {
      return `${user.organisation.slice(0, 40)}...`;
    } else return user.organisation;
  };

  return (
    <div className="person">
      <Button
        className="person__container"
        onClick={() => {
          ModalOpener.instance.open({
            render: () => <UserModal user={user} />,
          });
        }}
        theme="wrapper"
      >
        {user?.image ? <img alt="speaker-image" src={user.image} /> : <Icon name="SvgUser" size={5} />}
        <p className="person__name">{getUserName(user)}</p>
        <p className="person__organisation">{getUserOrganisation() || ' '}</p>
      </Button>
    </div>
  );
};
export default Person;
