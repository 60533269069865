import { FC } from 'react';

import classnames from 'classnames';

import { Button, Icon } from '../../_shared';
import { DATE_MONTH_FORMAT, formatISOString, getDaysDifference, TIME_STRING_FORMAT } from '../../_utils/dateHelpers';
import { getLabelType } from '../../_utils/eventHelpers';
import CardLabel from '../card/components/CardLabel';

import './pageBanner.scss';

type TProps = {
  attendingInfo?: {
    attendeesCount: number;
    eventId: string;
    isUserAttending: boolean;
  };
  description?: string;
  endDate?: string;
  image: string;
  label: string;
  link?: string;
  registrationUrl?: string;
  startDate?: string;
  title: string;
  type: 'text-box' | 'text-overlay' | 'plain-background' | '';
};

const PageBanner: FC<TProps> = ({ description, endDate, image, label, link, startDate, title, type }) => {
  return (
    <section
      className={classnames('ecl-banner ecl-banner--box-bg-light ecl-banner--color-dark', {
        'ecl-banner--plain-background': type === 'plain-background',
        'ecl-banner--text-box': type === 'text-box',
        'ecl-banner--text-overlay': type === 'text-overlay',
      })}
    >
      <figure className="ecl-banner__picture-container">
        <picture className="ecl-picture ecl-banner__picture" data-ecl-banner-image>
          <img alt="" className="ecl-banner__image" src={image} />
        </picture>
      </figure>
      <div className="ecl-container">
        <div className="ecl-banner__container">
          <div className="ecl-banner__content" data-ecl-banner-container>
            {startDate && endDate && <CardLabel type={getLabelType(startDate, endDate)} />}
            {title && <div className="ecl-banner__title">{title}</div>}
            {description && <p className="ecl-banner__description">{description}</p>}
            {startDate && (
              <ul className="ecl-banner-timing ecl-u-d-flex">
                <li className="ecl-banner-timing__item ecl-u-mr-l">
                  <Icon name="calendar" size="xs" />
                  <span className="ecl-banner-timing__item__label">
                    {formatISOString(startDate, DATE_MONTH_FORMAT)}
                    {getDaysDifference(startDate, endDate) > 0 && ` - ${formatISOString(endDate, DATE_MONTH_FORMAT)}`}
                  </span>
                </li>
                <li className="ecl-banner-timing__item">
                  <Icon name="SvgClock" size="xs" />
                  <span className="ecl-banner-timing__item__label">{formatISOString(startDate, TIME_STRING_FORMAT)}</span>
                </li>
              </ul>
            )}
            <div className="ecl-banner__cta">
              {link && (
                <Button
                  className="ecl-banner__link-cta"
                  href={link}
                  icon="corner-arrow"
                  iconSize="xs"
                  iconTransformation="rotate-90"
                  theme="cta-link"
                >
                  {label}
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PageBanner;
