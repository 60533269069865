import { FC } from 'react';

import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import { IntroCommunity } from '../community/_components';
import { FeaturedEvents } from '../events/_components';
import ExternalHighlights from '../externalHighlights/ExternalHighlights';
import { CommunityPlatform } from '../howItWorks/_components';

import { Highlights } from './_components';

const Home: FC = () => {
  const { t } = useTranslation();

  return (
    <main className="home">
      <Helmet>
        <title>{t('SHARED.NAVIGATION.HOME')}</title>
      </Helmet>
      <Highlights />
      <FeaturedEvents />
      <IntroCommunity />
      <CommunityPlatform />
      <ExternalHighlights />
    </main>
  );
};

export default Home;
