import { FC, useCallback, useState } from 'react';

import { eachDayOfInterval } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { FilterBar, Checkbox, SearchField, Spinner, Tabbar, Title } from '../_shared';
import { DEFAULT_YEAR_STRING_FORMAT, formatDate } from '../_utils/dateHelpers';
import { getCurrentProgrammeDay, getTimeblocks } from '../_utils/eventHelpers';
import { useEventContext } from '../events/_context/EventContext';
import { SessionType } from '../sessions/_models';
import { useGetChannels } from '../sessions/_queries';

import { Session } from './_components';
import { TProgrammeQuery } from './_models';
import { useGetProgramme } from './_queries';
import './programme.scss';

const Programme: FC = () => {
  const { t } = useTranslation();
  const { event, topics } = useEventContext();
  const { state } = useLocation();

  const [query, setQuery] = useState<TProgrammeQuery>({
    channelId: '',
    date: getCurrentProgrammeDay(event.startDate, event.endDate),
    favoritesOnly: false,
    search: '',
    topics: state?.topics ?? [],
    types: [SessionType.Live],
  });
  const [tab, setTab] = useState(getCurrentProgrammeDay(event.startDate, event.endDate));

  const { data: channels, isLoading: channelsLoading } = useGetChannels();
  const { data: sessions, isLoading: sessionsLoading } = useGetProgramme(query);
  const { data: workshops, isLoading: workshopsLoading } = useGetProgramme({ types: [SessionType.Workshop] });

  const updateQuery = (overrides: Partial<TProgrammeQuery>) => setQuery({ ...query, ...overrides });
  const handleChannelsChange = (checked: boolean, id: string) => updateQuery({ channelId: checked ? id : '' });
  const handleTopicsChange = (checked: boolean, name: string) => {
    updateQuery({ topics: checked ? [...query.topics, name] : query.topics.filter(topic => topic !== name) });
  };
  const handleTabChange = (id: string) => {
    if (id === SessionType.Workshop) updateQuery({ date: undefined, types: [SessionType.Workshop] });
    else updateQuery({ date: id, types: [SessionType.Live] });
    setTab(id);
  };

  const getTabs = useCallback(() => {
    const tabs = [
      ...eachDayOfInterval({ end: new Date(event.endDate), start: new Date(event.startDate) }).map((date, index) => ({
        id: formatDate(date, DEFAULT_YEAR_STRING_FORMAT),
        label: t('SHARED.DAY_COUNT', { count: index + 1 }),
      })),
    ];

    if (workshops?.length) tabs.push({ id: SessionType.Workshop, label: t('EVENT.NAVIGATION.WORKSHOPS') });
    return tabs;
  }, [event, workshops]);

  const getActivetopics = useCallback(() => {
    const activeTopics: Set<string> = new Set(sessions?.flatMap(({ topics }) => topics));
    return activeTopics;
  }, [sessions]);

  if (workshopsLoading || channelsLoading) return <Spinner overlay />;
  return (
    <main className="programme ecl-container">
      <Title>{t('EVENT.NAVIGATION.PROGRAMME')}</Title>

      <div className="programme__content">
        <FilterBar clearFilters={() => updateQuery({ channelId: '', favoritesOnly: false, search: '', topics: [] })}>
          <SearchField label={t('EVENT.PROGRAMME.SEARCH_SESSION')} onSearch={search => updateQuery({ search })} />

          {Boolean(channels?.length) && <h4>{t('EVENT.PROGRAMME.LIVE_SESSIONS')}</h4>}
          {channels?.map(({ id, title }) => (
            <Checkbox checked={id === query?.channelId} key={id} name={id} onChange={handleChannelsChange}>
              {title}
            </Checkbox>
          ))}

          {getActivetopics()?.size && <h4>{t('SHARED.TOPICS')}</h4>}
          {topics.map(
            topic =>
              getActivetopics().has(topic.title) && (
                <Checkbox
                  checked={query?.topics.includes(topic.title)}
                  key={topic.title}
                  name={topic.title}
                  onChange={handleTopicsChange}
                >
                  {topic.title}
                </Checkbox>
              ),
          )}
        </FilterBar>

        <div className="programme__content__sessions">
          <Tabbar activeTabId={tab} tabChange={handleTabChange} tabs={getTabs()} />

          <section className="programme__content__sessions__slots">
            {sessionsLoading ? (
              <Spinner />
            ) : (
              <>
                {sessions?.length ? (
                  Object.entries(getTimeblocks(sessions, channels)).map(([key, sessions]) => (
                    <div className="programme__content__sessions__slots__timeblock" key={key}>
                      <span>{key}</span>
                      <div>
                        {sessions.map(session => (
                          <div className="programme__content__sessions__slots__timeblock__session" key={session.id}>
                            <div className="programme__content__sessions__slots__timeblock__session__bullet" />
                            <Session session={session} />
                          </div>
                        ))}
                      </div>
                    </div>
                  ))
                ) : (
                  <p>{t('EVENT.PROGRAMME.NO_SESSIONS')}</p>
                )}
              </>
            )}
          </section>
        </div>
      </div>
    </main>
  );
};
export default Programme;
