import { FC } from 'react';

import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import { About, Button, Documentation, ExternalResources, Spinner } from '../../_shared';
import Chatroom from '../../chat/Chatroom';
import { RelatedProjects } from '../../projects/_components';
import { RelatedSessions } from '../../sessions/_components';
import { Banner, Navigation } from '../_components';
import { useGetHub } from '../_queries';
import './detail.scss';

const Detail: FC = () => {
  const { t } = useTranslation();
  const { data: hub, isLoading } = useGetHub();

  if (isLoading) return <Spinner />;
  if (!hub && !isLoading) return null;
  return (
    <div className="hub-detail">
      <Helmet>
        <title>{hub?.title}</title>
      </Helmet>
      <div className="ecl-container back-navigation">
        <Button href=".." icon="corner-arrow" iconPosition="before" iconSize="xs" iconTransformation="rotate-270" theme="ghost">
          {t('SHARED.BUTTONS.BACK')}
        </Button>
      </div>
      <Banner announcements={hub.announcements} image={hub.image} title={hub.title} />
      <div className="ecl-container detail-content hub-detail__content">
        <main>
          <About summary={hub?.summary} title={t('HUBS.DETAIL.ABOUT')} topics={hub?.topics} />
          <Documentation documents={hub.documents} />
          <ExternalResources links={hub.links} />
        </main>
        <aside>
          <Navigation />
          <Chatroom chatroomId={hub.chatroomId} title={t('SHARED.LIVE_CHAT')} />
          <RelatedProjects pathPrefix="../" topics={hub.topics} />
          <RelatedSessions topics={hub.topics} />
        </aside>
      </div>
    </div>
  );
};

export default Detail;
