import { FC } from 'react';

import { useTranslation } from 'react-i18next';

import { Icon, Markdown } from '../../../_shared';
import { DATE_MONTH_FORMAT, formatISOString, TIME_STRING_FORMAT } from '../../../_utils/dateHelpers';
import { useEventContext } from '../../_context/EventContext';
import { getFormatDesc, getFormatLabel } from '../../_models';
import Map from '../map/Map';

import './attendInfo.scss';

const AttendInfo: FC = () => {
  const { t } = useTranslation();
  const { event } = useEventContext();

  return (
    <section className="attend-info">
      <div className="ecl-container">
        <div className="attend-info__content">
          <h2>{t('EVENT.OVERVIEW.HOW_TO_ATTEND')}</h2>
          <Markdown value={event.attendInfo?.json} />
        </div>
        <div>
          <div className="attend-info__info">
            <div>
              <Icon color="primary" name="calendar" size="l" />
              <h3>{t('EVENT.OVERVIEW.WHEN')}</h3>
              <span className="attend-info__info__bold">
                {formatISOString(event.startDate, DATE_MONTH_FORMAT)}
                {event.endDate &&
                  formatISOString(event.startDate, DATE_MONTH_FORMAT) != formatISOString(event.endDate, DATE_MONTH_FORMAT) &&
                  ' - ' + formatISOString(event.endDate, DATE_MONTH_FORMAT)}
              </span>
              <div>
                <Icon name="SvgClock" size="xs" />
                <span>{formatISOString(event.startDate, TIME_STRING_FORMAT)}</span>
              </div>
            </div>
            <div>
              <Icon color="primary" name="location" size="l" />
              <h3>{t('EVENT.OVERVIEW.FORMAT')}</h3>
              <div className="attend-info__info__bold">
                <Icon name="global" size="xs" />
                <span>{getFormatLabel(event.format)}</span>
              </div>
              <span>{getFormatDesc(event.format)}</span>
              {event.city && (
                <div className="attend-info__info__bold">
                  <span>
                    <Icon name="SvgLocation" size="xs" />
                    {event.city}
                  </span>
                </div>
              )}
              {event.address && <p>{event.address}</p>}
            </div>
          </div>
          <Map location={event?.location} />
        </div>
      </div>
    </section>
  );
};

export default AttendInfo;
