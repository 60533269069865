import { FC } from 'react';

import { useTranslation } from 'react-i18next';

import { Socials } from '../../../_shared';

import './engage.scss';

const Engage: FC = () => {
  const { t } = useTranslation();

  return (
    <div className="engage ecl-container">
      <div className="ecl-social-media-share">
        <p className="ecl-social-media-share__description">{t('EVENT.ENGAGE.SHARE')}</p>
        <Socials />
      </div>
    </div>
  );
};
export default Engage;
