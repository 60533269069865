import { FC } from 'react';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { getUserName, TUser } from '../_models';
import { useGetPostsByAuthor } from '../_queries';
import { Button, Checkbox, Title } from '../_shared';
import Icon from '../_shared/icon/Icon';
import { getSocials } from '../_utils/userHelpers';
import { useAuthContext } from '../auth/_context';
import { Config } from '../config';
import CardTag from '../ec/card/components/CardTag';
import { Item as NetworkListItem } from '../network/_components';
import { useGetUsers } from '../network/_queries';
import { Item as PostListItem } from '../posts/_components';
import { TPost } from '../posts/_models';
import { useGetPosts } from '../posts/_queries';
import { Item as ProjectListItem } from '../projects/_components';
import { TProject } from '../projects/_models';
import { useGetProjects } from '../projects/_queries';

import { ProfileList } from './_components';
import { useUpdateAccountSettings } from './_queries';

import './profile.scss';

const Profile: FC = () => {
  const { t } = useTranslation();
  const { profile } = useAuthContext();
  const socials = getSocials(profile);
  const navigate = useNavigate();

  const { data: projects, isLoading: projectsLoading } = useGetProjects({
    allFavorites: true,
    skip: 0,
    take: 100,
  });
  const { data: users, isLoading: usersLoading } = useGetUsers({ allIFollow: true, skip: 0, take: 3 });
  const { data: postsIWrote, isLoading: postsIWroteLoading } = useGetPostsByAuthor(profile.id);
  const { data: postsILiked, isLoading: postsILikedLoading } = useGetPosts({ isFavorite: true });
  const { mutate: updateAccountSettings } = useUpdateAccountSettings();

  return (
    <div className="detail-content ecl-container profile">
      <main className="user-detail">
        <Title heading="h1">
          {t('SHARED.NAVIGATION.PROFILE')}
          <Button href={Config.updateUrl} icon="SvgPencil" iconSize={0.875} theme="ghost">
            {t('PROFILE.EDIT')}
          </Button>
        </Title>
        <section className="user-detail__information">
          <h2>{t('PROFILE.INFO')}</h2>
          <div className="user-detail__information__content">
            {profile?.image ? <img alt={t('PROFILE.IMAGE_ALT')} src={profile.image} /> : <Icon name="SvgUser" size={8} />}
            <div className="user-detail__information__content__text">
              <h3>{getUserName(profile)}</h3>
              <div className="user-info-line">
                <Icon name="SvgWork" size={1} />
                <div>
                  <span>{`${profile?.organisationType || ''}${
                    profile?.organisation ? ' - ' : profile?.organisationType ? '' : '-'
                  }${profile?.organisation || ''}`}</span>
                  <span>{t('PROFILE.POSITION', { position: profile?.position || '-' })}</span>
                </div>
              </div>
              <div className="user-info-line">
                <Icon name="SvgLocation" size={1} />
                <span>{t('PROFILE.LOCATION', { location: profile?.nationality || '-' })}</span>
              </div>
            </div>
          </div>
        </section>
        <section className="profile__bio">
          <h2>{t('PROFILE.BIO')}</h2>
          <p>{profile?.biography}</p>
        </section>
        {profile?.interests?.length > 0 && (
          <section className="user-detail__topics">
            <h2>{t('PROFILE.TOPICS')}</h2>
            <div>
              {profile?.interests.sort().map(interest => (
                <CardTag key={interest} tag={interest} />
              ))}
            </div>
          </section>
        )}
        {!!socials?.length && (
          <section className="user-detail__socials">
            <h2>{t('PROFILE.SOCIALS')}</h2>
            {socials.map(social => (
              <Button href={social.url} icon={social.icon} iconPosition="before" key={social.url} theme="ghost">
                {social.name}
              </Button>
            ))}
          </section>
        )}
        {(profile?.email || profile?.website) && (
          <section className="user-detail__contact">
            <h2>{t('PROFILE.CONTACT')}</h2>
            {profile?.email && (
              <Button href={`mailto:${profile?.email}`} icon="SvgMailOutline" iconPosition="before" theme="plain-link">
                {profile?.email}
              </Button>
            )}
            {profile?.website && (
              <Button href={profile?.website} icon="SvgLink" iconPosition="before" theme="plain-link">
                {profile?.website}
              </Button>
            )}
          </section>
        )}
      </main>

      <aside>
        <section className="profile__availability">
          <h3>{t('PROFILE.AVAILABILITY')}</h3>
          <Checkbox
            checked={profile.isVisible}
            name="visible"
            onChange={() => updateAccountSettings({ canContact: profile.canContact, isVisible: !profile.isVisible })}
            type="toggle"
          >
            {t('PROFILE.VISIBLE')}
          </Checkbox>
          <Checkbox
            checked={profile.canContact}
            name="contact"
            onChange={() => updateAccountSettings({ canContact: !profile.canContact, isVisible: profile.isVisible })}
            type="toggle"
          >
            {t('PROFILE.CONTACT_YOU')}
          </Checkbox>
        </section>
        <ProfileList
          data={users?.data}
          loading={usersLoading}
          onShowAll={() => navigate('/community/network', { state: { allIFollow: true } })}
          renderContent={(user: TUser) => <NetworkListItem key={user.id} user={user} />}
          title={t('PROFILE.NETWORK')}
        />
        <ProfileList
          data={projects?.data}
          loading={projectsLoading}
          renderContent={(project: TProject) => <ProjectListItem key={project.id} project={project} />}
          title={t('PROFILE.PROJECTS_INTERESTED')}
        />
        {Config.showCommunity && (
          <>
            <ProfileList
              data={postsIWrote}
              loading={postsIWroteLoading}
              onShowAll={() => navigate('/community/posts')}
              renderContent={(post: TPost) => <PostListItem key={post.id} post={post} />}
              title={t('PROFILE.POSTS_I_WROTE')}
            />
            <ProfileList
              data={postsILiked?.data.slice(0, 5)}
              loading={postsILikedLoading}
              onShowAll={() => navigate('/community/posts', { state: { isFavorite: true } })}
              renderContent={(post: TPost) => <PostListItem key={post.id} post={post} />}
              title={t('PROFILE.POSTS_I_FOLLOW')}
            />
          </>
        )}
      </aside>
    </div>
  );
};

export default Profile;
