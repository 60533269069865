import { createContext, FC, ReactNode, useContext, useState } from 'react';

import { TTopic } from '../../_models';
import { useGetTopics } from '../../_queries';
import { TEvent, TEventsQuery } from '../_models';
import { useGetEvents } from '../_queries';

type TEventsContext = {
  currentEvents: TEvent[];
  isLoading: boolean;
  pastEvents: TEvent[];
  query: TEventsQuery;
  setQuery: (overrides: Partial<TEventsQuery>) => void;
  topics: TTopic[];
  upcomingEvents: TEvent[];
};

const initialQuery: TEventsQuery = {
  search: '',
  topics: [],
};

const EventsContext = createContext<TEventsContext>({
  currentEvents: [],
  isLoading: false,
  pastEvents: [],
  query: initialQuery,
  setQuery: () => {},
  topics: [],
  upcomingEvents: [],
});

export const useEventsContext = () => useContext(EventsContext);

type TProps = {
  children: ReactNode;
};

export const EventsContextProvider: FC<TProps> = ({ children }) => {
  const [query, setQuery] = useState<TEventsQuery>(initialQuery);
  const { isLoading, data } = useGetEvents(query);
  const { data: topics } = useGetTopics();

  function updateQuery(overrides: Partial<TEventsQuery>) {
    setQuery({ ...query, ...overrides });
  }

  return (
    <EventsContext.Provider
      value={{
        currentEvents: data?.currentEvents ?? [],
        isLoading,
        pastEvents: data?.pastEvents ?? [],
        query,
        setQuery: updateQuery,
        topics: topics ?? [],
        upcomingEvents: data?.upcomingEvents ?? [],
      }}
    >
      {children}
    </EventsContext.Provider>
  );
};
